<template>
  <div class="pa-5" v-if="allowEdit">
    <div class="d-flex">
      <v-btn class="mr-5" color="#122333" @click="$router.go(-1)">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="text-h5 pb-5 font-weight-bold">Edit Job</div>
    </div>
    <v-row>
      <v-col cols="8">
        <label for="role" class="font-weight-bold text-h6"> Job Role </label>
        <v-text-field
          v-model="jobRole"
          id="role"
          outlined
          placeholder="Title of Role"
          :rules="[(v) => !!v || 'Required']"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <label for="location" class="font-weight-bold text-h6">
          Location
        </label>
        <v-combobox
          v-model="location"
          :items="[]"
          chips
          small-chips
          multiple
          outlined
          placeholder="Enter location"
          :rules="[(v) => v.length > 0 || 'Required']"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <label for="jobType" class="font-weight-bold text-h6"> Job Type </label>
        <v-select
          v-model="jobType"
          :items="jobTypes"
          item-value="value"
          item-text="name"
          placeholder="Select Job Type"
          :rules="[(v) => !!v || 'Required']"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <label for="experience" class="font-weight-bold text-h6">
          Experience
        </label>
        <v-text-field
          v-model="rawExpTxt"
          placeholder="Eg., 1-3"
          :rules="[
            (v) => !!v || 'Required',
            (v) => expFormatValid || 'Invalid Format',
          ]"
          @input="parseExperience"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <label for="preference" class="font-weight-bold text-h6">
          Job Preference
        </label>
        <v-combobox
          v-model="preference"
          :items="jobPrefTypes"
          chips
          small-chips
          multiple
          item-value="value"
          item-text="name"
          placeholder="Select Job Preference"
          :rules="[(v) => !!v || 'Required']"
          outlined
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label for="description" class="font-weight-bold text-h6">
          Description
        </label>
        <v-textarea
          v-model="description"
          outlined
          placeholder="Job Description"
          :rules="[(v) => !!v || 'Required']"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-card elevation="0">
        <v-card-text>
          <v-row>
            <div class="text-h6 font-weight-bold">Selected Skills</div>
          </v-row>
          <v-row>
            <v-chip-group column>
              <v-chip
                v-for="(sel, idx) in selectedSkills"
                :key="idx"
                color="success"
                close
                @click:close="removeSelectedSkill(idx)"
              >
                {{ sel }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                dense
                v-model="newSkill"
                @keyup.enter="addCustomSkill"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn text @click="addCustomSkill" color="primary" outlined>
                <v-icon left>
                  {{ mdiPlus }}
                </v-icon>
                <span> Add Skill </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <div class="text-h6 font-weight-bold">Quick Pick Skills</div>
            <v-chip-group v-model="selectedSkills" column multiple>
              <v-chip
                v-for="skill in skills"
                :key="skill"
                :value="skill"
                active-class="success white--text"
              >
                {{ skill }}
              </v-chip>
            </v-chip-group>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col class="pb-0 mb-0">
        <label for="currency" class="text-h6 font-weight-bold">
          Salary Range
          <span class="text-body-2 grey--text">per month</span>
        </label>
      </v-col>
    </v-row>
    <v-row>
      <!-- salary -->
      <v-col cols="3">
        <v-select
          v-model="currency"
          outlined
          :items="['INR', 'USD', 'GBP', 'EURO']"
          placeholder="Currency"
          dense
          :rules="[(v) => !!v || 'Required']"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-row">
          <v-text-field
            outlined
            placeholder="Min. Salary"
            v-model="minSalary"
            dense
            :rules="[(v) => !!v || 'Required']"
          ></v-text-field>
          <span class="mt-2 px-2"> to </span>
          <v-text-field
            outlined
            placeholder="Max. Salary"
            v-model="maxSalary"
            dense
            :rules="[(v) => !!v || 'Required']"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <!-- Benefits -->
    <v-row justify="center" align="center">
      <v-col class="d-flex flex-row">
        <label for="Benefits" class="text-h6 font-weight-bold">
          Benefits
        </label>
        <v-btn color="grey" @click="benefits = defaultBenefits" icon>
          <v-icon>
            {{ mdiRestore }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row flex-wrap">
      <v-checkbox
        v-for="(benefit, idx) in benefits"
        :key="idx"
        :label="benefit"
        :value="benefit"
        v-model="benefits"
        hide-details
        class="px-3"
      >
      </v-checkbox>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          v-model="newBenefit"
          @keyup.enter="addBenefit"
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn text @click="addBenefit" color="primary" outlined>
          <v-icon left>
            {{ mdiPlus }}
          </v-icon>
          <span> Add Benefit </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- Equity -->
      <v-col cols="6">
        <label for="currency" class="text-h6 font-weight-bold"> Equity </label>
        <div class="d-flex flex-row pt-3">
          <v-text-field
            outlined
            placeholder="Min. Equity %"
            dense
            :rules="[(v) => !!v || 'Required']"
            v-model="minEquityPercent"
          ></v-text-field>
          <span class="mt-2 px-2"> to </span>
          <v-text-field
            outlined
            placeholder="Max. Equity %"
            dense
            :rules="[(v) => !!v || 'Required']"
            v-model="maxEquityPercent"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label for="expiry-picker" class="text-h6 font-weight-bold">
          Expiry Date
        </label>
        <div>
          <v-date-picker
            id="expiry-picker"
            :allowed-dates="allowedDatesForLink"
            v-model="expiryEpoch"
            elevation="1"
          >
          </v-date-picker>
        </div>
      </v-col>
    </v-row>
    <div class="save-btn">
      <v-btn
        x-large
        aria-label="Save Button"
        @click="submit"
        fab
        :loading="saving"
      >
        <v-icon x-large color="success">
          {{ mdiFloppy }}
        </v-icon>
      </v-btn>
    </div>
  </div>
  <div class="text-center text-h6" v-else>Loading ...</div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import {
  mdiPlus,
  mdiMinus,
  mdiTimer,
  mdiRestore,
  mdiInformation,
  mdiArrowLeft,
  mdiFloppy,
} from "@mdi/js";
import { mdiLockOutline } from "@mdi/js";
import SkillModal from "../../components/recruiter/SkillModal.vue";
import GoPremium from "../../components/assessment/GoPremium.vue";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";

export default {
  components: { SkillModal, GoPremium },
  data() {
    return {
      pos: 1,
      jobRole: "",
      description: "",
      location: [],
      preference: [],
      jobType: "",
      rawExpTxt: "",
      currency: "",
      defaultBenefits: ["Unlimited PTO", "Device Budget"],
      benefits: [],
      newBenefit: "",
      newSkill: "",
      minExp: null,
      maxExp: null,
      minEquityPercent: null,
      maxEquityPercent: null,
      minSalary: null,
      maxSalary: null,
      jobTypes: [
        {
          name: "Select Job Type",
          value: "JOB_TYPE_UNKNOWN",
        },
        {
          name: "Full Time",
          value: "FULL_TIME",
        },
        {
          name: "Internship",
          value: "INTERNSHIP",
        },
        {
          name: "Contract",
          value: "CONTRACT",
        },
      ],
      jobPrefTypes: [
        {
          name: "Remote",
          value: "REMOTE",
        },
        {
          name: "On-Site",
          value: "ONSITE",
        },
        {
          name: "Hybrid",
          value: "HYBRID",
        },
      ],
      skills: [],
      selectedSkills: [],
      basicStep: true,
      salaryBenefitsForm: false,
      mdiPlus,
      mdiMinus,
      mdiTimer,
      mdiInformation,
      mdiArrowLeft,
      mdiRestore,
      mdiFloppy,
      mdiLockOutline,
      saving: false,
      expiryEpoch: "",
      allowEdit: false,
    };
  },
  mixins: [formattedTimeMixin],
  methods: {
    ...mapActions("jobs", [
      "createJob",
      "saveUpdatedJob",
      "fetchJob",
      "fetchJobContent",
      "fetchJobSkills",
    ]),
    ...mapMutations("jobs", ["setJob", "setJobResponse"]),
    addBenefit() {
      this.benefits.push(this.newBenefit);
      this.newBenefit = "";
    },
    addCustomSkill() {
      this.selectedSkills.push(this.newSkill);
      this.newSkill = "";
    },
    removeSelectedSkill(del) {
      console.log(del, this.selectedSkills[del]);
      this.selectedSkills.splice(del, 1);
      this.selectedSkills = [...this.selectedSkills];
    },
    submit() {
      this.saving = true;
      console.log("JB is ..", this.jobType)
      this.saveUpdatedJob({
        about: this.description,
        skills: this.selectedSkills,
        role: this.jobRole,
        preference: this.preference.map((v) => v.value),
        minCtc: this.minSalary,
        maxCtc: this.maxSalary,
        minEquity: this.minEquityPercent,
        maxEquity: this.maxEquityPercent,
        benefitsList: this.benefits,
        currency: this.currency,
        jobType: this.jobType,
        minExp: this.minExp,
        maxExp: this.maxExp,
        locationList: this.location,
        expiry: this.expiryEpoch,
      })
        .then(() => {
          this.$router.push(`/jobs/${this.jobView.getUrl()}`);
        })
        .catch((err) => {
          console.log(err, this.jobView?.toObject());
          this.saving = false;
        });
    },
    parseExperience() {
      if (this.expFormatValid) {
        if (this.rawExpTxt?.includes("-")) {
          [this.minExp, this.maxExp] = this.rawExpTxt
            .split("-")
            .map((v) => !!v && parseInt(v));
        } else {
          this.minExp = parseInt(this.rawExpTxt);
        }
      }
      console.log(this.minExp, this.maxExp);
    },
    allowedDatesForLink(selection) {
      // yyyy-mm-dd is the internal rep of date in v-date-picker
      // allowed date should be greater than today's date
      const selectedDate = new Date(selection);
      const today = new Date();
      return selectedDate > today;
    },
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("jobs", ["jobView", "job", "jobSkills"]),
    ...mapGetters("customer", ["hiringPlanKeys", "prettyHiringPlans"]),
    expFormatValid() {
      this.rawExpTxt = this.rawExpTxt.trim();
      // https://stackoverflow.com/a/32311188
      // symbols regex (only without "-" symbol)
      if (
        this.rawExpTxt.startsWith("-") ||
        /[a-zA-Z]/g.test(this.rawExpTxt) ||
        /[ `!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/.test(this.rawExpTxt) ||
        Number.isNaN(parseInt(this.rawExpTxt[0]))
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.fetchJobContent({ url: this.$route.params.url }).then(() => {
      // get existing values and place into proper place
      this.jobRole = this.jobView?.getDataView()?.getJobView()?.getRole();
      this.description = this.job?.getData()?.getJob()?.getAbout();
      this.selectedSkills = [
        ...this.jobView
          ?.getDataView()
          ?.getJobView()
          ?.getSkillsList()
          .map((v) => v?.getSkill()),
      ];
      const jobMeta = this?.jobView?.getMetaData()?.getJobMeta();
      this.rawExpTxt = `${jobMeta?.getMinExperience()}-${jobMeta?.getMaxExperience()}`;
      this.location = jobMeta?.getLocationList();
      this.currency = jobMeta?.getCtc()?.getCurrency();
      this.minSalary = jobMeta?.getCtc()?.getMinCtc();
      this.maxSalary = jobMeta?.getCtc()?.getMaxCtc();
      this.benefits = jobMeta?.getCtc()?.getOtherBenefitsList();
      this.minEquityPercent = jobMeta?.getCtc()?.getMinEquity();
      this.maxEquityPercent = jobMeta?.getCtc()?.getMaxEquity();

      console.log("JOB META ...", jobMeta.getJobType())
      this.jobType = this.jobTypes[jobMeta?.getJobType()].value;
      console.log("JOBTYPE is....", this.jobType);
      this.preference = jobMeta
        ?.getPreferenceList()
        .map((v) => this.jobPrefTypes[v - 1]);

      const exp = new Date(jobMeta?.getExpiryDate());
      this.expiryEpoch = `${exp.getFullYear()}-${
        exp?.getMonth() + 1
      }-${exp?.getDate()}`;
      this.setJobResponse(this.jobView);
      this.allowEdit = true;
    });
    this.fetchJobSkills().then(() => {
      this.skills = this.jobSkills.map((v) => v.getSkill());
    });
  },
};
</script>
<style scoped>
.save-btn {
  position: fixed;
  bottom: 10%;
  right: 10%;
}
</style>