<template>
  <v-card outlined rounded="lg" elevation="0">
    <v-card-title class="text-h5 font-weight-bold justify-space-between">
      <div class="d-flex">
        <v-btn class="px-n3" color="#122333" @click="handleBack">
          <v-icon color="white">
            {{ mdiArrowLeft }}
          </v-icon>
        </v-btn>
        <div class="ml-5">
          {{ jobView?.getTitle() ?? "Loading ..." }}
        </div>
      </div>
      <v-btn
        class="text-none"
        color="success"
        elevation="0"
        :to="`${$route.fullPath}/edit`"
      >
        Edit Job
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel class="mt-5">
          <v-expansion-panel-header class="text-h6 font-weight-bold">
            Information
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0" class="pa-5 text-body-1">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold"> Posted On </v-col>
                    <v-col cols="6">
                      {{ formatDate(jobView?.getCreatedOn()) || "-" }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Expiry On
                    </v-col>
                    <v-col cols="6">
                      {{
                        new Date(jobMetaData?.getExpiryDate()).toDateString() ||
                        "-"
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Applicants
                    </v-col>
                    <v-col cols="6">
                      {{ jobMetaData?.getCandidatesApplied() }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <v-card :loading="jobLoading" elevation="0">
              <v-card-title class="primary--text">Details</v-card-title>
              <v-card-text class="text-body-1 black--text">
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold">
                        Job Role
                      </v-col>
                      <v-col cols="6">{{ jobInfo?.getRole() }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold">
                        Location
                      </v-col>
                      <v-col cols="6">
                        {{ jobMetaData?.getLocationList()?.join(", ") }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold">
                        Job Type
                      </v-col>
                      <v-col cols="6">
                        {{ jobType(jobTypeKeys[jobMetaData?.getJobType()])}}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold">
                        Experience
                      </v-col>
                      <v-col cols="6">
                        {{ jobMetaData?.getMinExperience() }}-{{
                          jobMetaData?.getMaxExperience()
                        }}
                        years
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold">
                        Salary Range
                      </v-col>
                      <v-col cols="6">
                        {{ jobCtc?.getCurrency() }} {{ jobCtc?.getMinCtc() }} to
                        {{ jobCtc?.getMaxCtc() }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" class="font-weight-bold"> Equity </v-col>
                      <v-col cols="6">
                        {{ parseFloat(jobCtc?.getMinEquity()) }}% to
                        {{ parseFloat(jobCtc?.getMaxEquity()) }}%
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold">
                        Description
                      </v-col>
                      <v-col cols="10">
                        <html-view :html="jobInfo?.getAbout()"></html-view>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold"> Skills </v-col>
                      <v-col cols="10">
                        <v-chip-group column>
                          <v-chip
                            v-for="(sel, idx) in jobInfo?.getSkillsList()"
                            :key="idx"
                            color="success"
                          >
                            {{ sel.getSkill() }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold">
                        Preference
                      </v-col>
                      <v-col cols="10">
                        {{ preferenceList?.join(", ") }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold">
                        Benefits
                      </v-col>
                      <v-col cols="10">
                        <ul>
                          <li v-for="benefit in allBenefits" :key="benefit">
                            {{ benefit }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-5">
          <v-expansion-panel-header class="text-h6 font-weight-bold">
            Candidates
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ma-0 pa-0">
            <v-card elevation="0">
              <v-card-text class="ma-0 pa-0">
                <candidates :displayCandidates="jobCandidatesTable" :candidateTableLoading="candidateTableLoading" tableType="JOB"></candidates> 
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-5">
          <v-expansion-panel-header
            class="text-h6 font-weight-bold d-flex justify-space-between"
          >
            <div>Assessment</div>
            <div class="d-flex flex-row justify-end">
              <!-- <v-btn
                v-if="!linkedTest"
                @click.stop="getRecruiterTests"
                color="primary"
                elevation="-1"
                class="text-none mx-3"
              >
                Add Assessment
              </v-btn> -->
              <v-btn
                v-if="!linkedTest"
                :to="`/assessments/create/${jobView?.getId()}`"
                color="primary"
                elevation="0"
                class="text-none mx-2"
              >
                Create Assessment
              </v-btn>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="linkedTest">
              <v-col cols="4" class="font-weight-bold">
              <router-link :to="`/assessments/${testUrl}`"> {{this.jobView?.getChildPreviewsList()[0]?.getContentPreview()?.getTitle()}} </router-link>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="4" class="font-weight-bold">
              Linked to one of the following assessment
              </v-col>
              <v-col cols="10">
                <v-radio-group
                  column
                  v-model="selectedTestForJob"
                  :readonly="linkedTest"
                >
                  <v-radio
                    v-for="test in displayTests"
                    :key="test.id"
                    :value="test.id"
                    :label="test?.name"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="2" class="d-flex flex-column-reverse">
                <v-btn
                  color="primary"
                  v-if="!linkedTest"
                  elevation="0"
                  class="text-none"
                  @click="saveJobTestLink"
                  :loading="linking"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUpload, mdiArrowLeft, mdiTrashCan, mdiMagnify } from "@mdi/js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { ApiCallStatus } from "@/utils/api";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";
import SmallConfirmation from "@/components/assessment/SmallConfirmation.vue";
import moment from "moment";
import HtmlView from '@/components/HtmlView.vue';
import Candidates from "@/components/recruiter/Candidates.vue";

export default {
  data() {
    return {
      candidateTableLoading: false,
      mdiCloudUpload,
      mdiArrowLeft,
      mdiTrashCan,
      mdiMagnify,
      panels: 0,
      jobLoading: true,
      selectedTestForJob: null,
      displayTests: [],
      jobCandidatesTable: [],
      linking: false,
      linkedTest: false,
      jobCandidateHeaders: [
        {
          text: "Candidate",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Applied On",
          value: "applied",
        },
        // {
        //   text: "Test Status",
        //   value: "status",
        // },
        // {
        //   text: "Stage",
        //   value: "stage",
        // },
        // {
        //   text: "Rating",
        //   value: "rating",
        //   align: "center",
        //   sort: (a, b) => b - a,
        // },
        // {
        //   text: "Delete",
        //   value: "delaction",
        // },
      ],
    };
  },
  mixins: [formattedTimeMixin],
  props: {},
  methods: {
    ...mapActions("jobs", [
      "fetchJob",
      "linkJobWithTest",
      "fetchJobCandidates",
    ]),
    ...mapActions("recruiter", ["fetchCustomerAllTest"]),
    formatDate(time) {
      console.log("Posted on", time)
      return moment.unix(time / 1000).format("DD MMM YY");
    },
    handleBack() {
      this.$router.push("/jobs");
    },
    saveJobTestLink() {
      this.linking = true;
      this.linkJobWithTest({
        test_id: this.selectedTestForJob,
        job_id: this.job?.getId(),
      })
        .then(() => {
          this.$store.dispatch("notifs/addNotif", {
            text: "Successfully linked test with Job!",
            type: "success",
          });
          this.linking = false;
          this.linkedTest = true;
        })
        .catch(() => {
          this.linking = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Error occured while linking test with Job, please try again later!",
            type: "error",
          });
        });
    },
    getRecruiterTests() {
      this.panels = 2;
    },
    populateJobDetails() {
      this.fetchJob({ url: this.$route.params.url }).then(() => {
        this.selectedTestForJob = this.jobInfo?.getTestId();
        if(this.jobInfo?.getTestId() != 0) this.linkedTest = true;
        this.fetchJobCandidates({ id: this.jobView?.getId() }).then(() => {
          this.jobCandidatesTable = [];
          this.allJobCandidates.forEach((cd) => {
            this.jobCandidatesTable.push({
              name: cd?.getResume()?.getName(),
              email: cd?.getResume()?.getEmail(),
              applied: new Date(cd?.getAppliedOn()).toDateString(),
              resumeLink: cd?.getUploadedResumeUrl()
            });
          });
          console.log("Candidates are ...", this.jobCandidatesTable)
        });
        if(!this.linkedToTest) this.fetchCustomerAllTest({}).then(() => {
          this.displayTests = this.allTests.map((test) => ({
            id: test?.getId(),
            name: test?.getTitle(),
          }));
        });
      });
    },
    jobType(job) {
      if(job === 'FULL_TIME')return'Full Time';
      if(job === 'INTERNSHIP')return 'Internship';
      if(job === 'CONTRACT')  return "Contract";
      return  '-';
    },
    async copyTestUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(this.testUrl);
        this.$store.dispatch("notifs/addNotif", {
          text: `Copied URL to clipboard!`,
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("notifs/addNotif", {
          text: `Erropr copying URL, try again!`,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState("jobs", ["jobView", "allJobCandidates", "fetchSingleJobStatus"]),
    ...mapGetters("jobs", ["jobTypeKeys", "jobPrefKeys"]),
    ...mapState("recruiter", ["allTests"]),
    jobMetaData() {
      return this.jobView?.getMetaData()?.getJobMeta();
    },
    jobInfo() {
      return this.jobView?.getDataView()?.getJobView();
    },
    jobCtc() {
      return this.jobMetaData?.getCtc();
    },
    allBenefits() {
      return this.jobCtc?.getOtherBenefitsList();
    },
    preferenceList() {
      return this.jobMetaData
        ?.getPreferenceList()
        .map((v) => this.jobPrefKeys[v]);
    },
    testUrl() {
      return this.jobView?.getChildPreviewsList()[0]?.getContentPreview()?.getUrl();
    },
  },

  created() {
    this.populateJobDetails();
    console.log(this.$route.fullPath);
  },
  components: { SmallConfirmation, HtmlView, HtmlView, Candidates },
  watch: {
    fetchSingleJobStatus() {
      console.log(`fetchSingleJobStatus`, this.fetchSingleJobStatus);
      if (this.fetchSingleJobStatus === ApiCallStatus.SUCCESS) {
        this.jobLoading = false;
      } else if (this.fetchSingleJobStatus === ApiCallStatus.ERROR) {
        this.jobLoading = true;
        this.$store.dispatch("notifs/addNotif", {
          text: "Error loading assessment, please reload!",
          type: "error",
        });
      } else {
        this.jobLoading = true;
      }
    },
  },
};
</script>
<style scoped>
tr {
  cursor: pointer;
}
</style>
